var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.form.id ? 'Update Route' : 'Create New Route')+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Port of Origin")]),_c('ValidationProvider',{attrs:{"name":"port_origin_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('el-select',{staticClass:"form-group",class:[
                { 'has-danger': failed },
                { 'has-success': passed }],attrs:{"placeholder":"Select","filterable":""},model:{value:(_vm.form.port_origin_id),callback:function ($$v) {_vm.$set(_vm.form, "port_origin_id", $$v)},expression:"form.port_origin_id"}},_vm._l((_vm.ports),function(p){return _c('el-option',{key:p.id,attrs:{"value":p.id,"label":p.name}},[_vm._v(_vm._s(p.name))])}),1),_c('br'),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Port of origin is required")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Port of Destination")]),_c('ValidationProvider',{attrs:{"name":"port_dest_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
                var failed = ref.failed;
return [_c('el-select',{staticClass:"form-group",class:[
                { 'has-danger': failed },
                { 'has-success': passed }],attrs:{"placeholder":"Select","filterable":""},model:{value:(_vm.form.port_dest_id),callback:function ($$v) {_vm.$set(_vm.form, "port_dest_id", $$v)},expression:"form.port_dest_id"}},_vm._l((_vm.ports),function(p){return _c('el-option',{key:p.id,attrs:{"value":p.id,"label":p.name}},[_vm._v(_vm._s(p.name))])}),1),_c('br'),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Port of destination is required")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Travel Time (Hours)")]),_c('ValidationProvider',{attrs:{"name":"travel_time","rules":"required|double"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
                var failed = ref.failed;
return [_c('el-input',{staticClass:"form-group",class:[
                { 'has-danger': failed },
                { 'has-success': passed }],attrs:{"placeholder":"0.00"},model:{value:(_vm.form.travel_time),callback:function ($$v) {_vm.$set(_vm.form, "travel_time", $$v)},expression:"form.travel_time"}}),_c('br'),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Travel time is not valid")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"card-footer text-right"},[_c('p-button',{attrs:{"type":"info","native-type":"submit"}},[_vm._v("Save")])],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }