<template>
  <div class="" style="padding-left: 100px;">
    <h4>ASSIGN SEAT</h4>
    <div class="row">

      <div class="col-md-6">
        <div style="text-align: left;">
          <ticket-assign-seat :ticket="selected" :booking="selected.booking" v-if="selected"
            @save="updateSeat($event)"></ticket-assign-seat>
        </div>
      </div>
      <div class="col-md-5">
        <el-button type="success" @click="handleAssignSeatNumberToAll()" v-if="!booking">AUTO-ASSIGN SEAT NUMBER TO ALL
          TICKETS</el-button>

        <el-alert :closable="false" type="dark" style="margin-top:10px">Tickets with an open return will not include
          a seat number.</el-alert>

        <el-table class="table-striped" header-row-class-name="text-primary" :data="tickets" style="width: 100%"
          :row-class-name="getSelectedRow">
          <el-table-column :min-width="150" class-name="td-actions" label="Passenger">
            <template slot-scope="props">
              <div style="font-size: 15px;font-weight: bold">{{ props.row.ticket_detail.passenger.full_name }}</div>
              <div>{{ props.row.accommodation.name }} | {{ props.row.booking.route.code }}</div>
              <div>{{ props.row.booking.booking_date }} | {{ props.row.booking.formatted_time }}</div>
            </template>
          </el-table-column>
          <el-table-column :min-width="150" class-name="td-actions" label="Seat No">
            <template slot-scope="props">
              <div>
                <el-button type="warning" plain size="large" @click="handleAssignSeat(props.row)"
                  v-if="!props.row.is_open">
                  <div style="font-size: 15px;">
                    {{
                    props.row.ticket_seat ? 'CHANGE' : 'ASSIGN'
                    }} <span v-if="props.row.ticket_seat" class="text-primary">{{ props.row.ticket_seat.seat_number
                      }}</span> <span>({{ props.row.accommodation.name }})</span>
                  </div>
                </el-button>
                <span class="text-danger" v-if="props.row.is_open">CANNOT ASSIGN SEAT TO OPEN TICKET</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>

import {
  Ticket
} from '@/resources/ticket'
import {
  GroupTicket
} from '@/resources/group_ticket'
import TicketAssignSeat from './TicketAssignSeat.vue';

export default {
  components: { TicketAssignSeat },
  props: ['group_ticket', 'all', 'booking'],
  data() {
    return {
      tickets: [],
      selected: null
    };
  },
  watch: {
    group_ticket: function () {
      this.initData()
    }
  },
  created: function () {
    this.initData()
  },
  methods: {
    initData() {
      if (this.group_ticket) {
        if (this.all) {
          this.handleAssignSeatNumberToAll()
        } else {
          this.getTickets()
        }
      } 
    },
    handleAssignSeatNumberToAll() {
      this.$loading = true
      GroupTicket.assignSeatNumberToAll(this.group_ticket.code)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Seat number has been assigned',
          });
          this.$emit('close', true)
          this.getTickets()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleAssignSeat(ticket) {
      this.selected = null
      this.selected = Object.assign({}, ticket)
    },
    updateSeat(result) {
      if (this.selected && result && result.ticket_id == this.selected.id) {
        let self = this
     
        this.tickets.forEach(function (ticket, i) {
          if (ticket.id == result.ticket_id) {
            ticket.ticket_seat = result
            self.selected = Object.assign({}, ticket)
            if (self.tickets[i + 1]) self.handleAssignSeat(self.tickets[i + 1])
            else {
              if (self.tickets.length == 1) {
                self.$emit('close', true)
              } else {
                self.$notify({
                  type: 'warning',
                  title: "Oops",
                  text: 'That was the last passenger',
                });
                self.$emit('close', true)
              }
             
            }
          }
        })
      }

    },
    getSelectedRow({row}) {
      return this.selected && this.selected.id == row.id ? 'selected-item':''
    },
    getTickets() {
      this.$loading = true

      let params = {
        page: 1,
        per_page: 9999,
        group_ticket_code: this.group_ticket?.code,
        booking_id: this.booking ? this.booking.id : null,
        not_open: true
      }

      this.selected = null

      Ticket.get(params)
        .then(result => {
          if (result.data.tickets)
            this.tickets = result.data.tickets
          if (this.tickets.length >= 1) {
            this.handleAssignSeat(this.tickets[0])
          }
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
