<template>
<div class="row">
  <el-drawer title="" :visible.sync="showForm" direction="btt" :before-close="handleClose" size="90%" :destroy-on-close="true">
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <promo-form @save="getPromos()" v-if="showForm" :promo="selected"></promo-form>
      </div>
    </div>

  </el-drawer>

  <el-drawer title="" :visible.sync="showView" direction="btt" :before-close="handleClose" size="90%" :destroy-on-close="true">
    <div v-if="selected">
      <promo-items-list :promo="selected" v-if="selected"></promo-items-list>
      <!-- <trip-schedule-view-trips :trip_schedule="selected"></trip-schedule-view-trips> -->
    </div>

  </el-drawer>

  <div class="col-md-12 card">
    <div class="card-header">
      <div class="category">Manage Promos</div>
    </div>
    <div class="card-body row">

      <div class="col-sm-6">
        <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page" size="small">
          <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
          </el-option>
        </el-select>
        <el-button type="primary" size="small" class="ml-2" @click="handleForm">New +</el-button>
      </div>
      <div class="col-sm-6">
        <div class="pull-right">
          <fg-input class="input-sm" placeholder="Search" v-model="term" addon-right-icon="nc-icon nc-zoom-split">
          </fg-input>
        </div>
      </div>
      <div class="col-md-12">
        <el-alert>The end date will automatically adjust based on the specified number of days from the current
          date.</el-alert>
      </div>
      <div class="col-sm-12 mt-2">
        <el-table class="table-striped" header-row-class-name="text-primary" :data="promos" style="width: 100%">
          <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Name">
            <template slot-scope="props">
              {{ props.row.name }}
            </template>
          </el-table-column>

          <el-table-column :min-width="50" fixed="right" class-name="td-actions" label="Start Date">
            <template slot-scope="props">
              {{ props.row.start_date }}
            </template>
          </el-table-column>

          <el-table-column :min-width="50" fixed="right" class-name="td-actions" label="End Date">
            <template slot-scope="props">
              {{ props.row.end_date }}
            </template>
          </el-table-column>

          <el-table-column :min-width="50" fixed="right" class-name="td-actions" label="Advance day">
              <template slot-scope="props">
                {{ props.row.advance_days }}
              </template>
            </el-table-column>


          <el-table-column :min-width="150" fixed="right" class-name="td-actions" label="Days to adjust from current date">
            <template slot-scope="props">
              {{ props.row.days_to_auto_adjust_end_date }}
            </template>
          </el-table-column>

          <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="is Round Trip?">
              <template slot-scope="props">
                {{ props.row.is_round_trip ? 'Yes':'No' }} {{ props.row.is_round_trip ? " ("+props.row.return_validity_days+' return days validity)' : ''}}
              </template>
            </el-table-column>

          <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Actions">
            <template slot-scope="props">

              <el-button type="default" link @click="handleView(props.row)" size="mini">
                View Items<i class="fa fa-eye"></i>
              </el-button>

              <el-button type="default" link @click="handleEdit(props.row)" size="mini">
                <i class="fa fa-edit"></i>
              </el-button>
              <confirm-delete @onClick="handleDelete(props.row)" :label="'Delete'"></confirm-delete>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="col-sm-6 pagination-info">
        <p class="category">Found {{ pagination.total }} data</p>
      </div>
      <div class="col-sm-6">
        <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total">
        </p-pagination>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  Promo
} from '@/resources/promo'
import ConfirmDelete from '../../Common/ConfirmDelete.vue'
import PromoForm from './PromoForm.vue'
import PromoItemsList from './PromoItemsList.vue'

export default {
  components: {
    ConfirmDelete,
    PromoForm,
    PromoItemsList,
  },
  data() {
    return {
      showDelete: false,
      showView: false,
      showForm: false,
      promos: [],
      selected: null,
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 5,
        currentPage: 1,
        total: 0
      },
      term: null,
    }
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getPromos()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getPromos()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getPromos()
    }
  },
  created: function () {
    this.getPromos()
  },
  methods: {
    handleEdit(model) {
      this.showForm = true
      this.selected = Object.assign({}, model)
    },
    handleView(model) {
      this.showView = true
      this.selected = Object.assign({}, model)
    },
    handleClose() {
      this.showDelete = false
      this.showForm = false
      this.showView = false
    },
    handleForm() {
      this.showForm = true
      this.selected = null
    },
    handleDelete(model) {
      this.$loading = true
      this.$loadingText = "Removing"

      Promo.delete({
          id: model.id
        })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Promo has been removed',
          });
          this.getPromos()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getPromos() {
      this.handleClose()
      let params = {
        term: this.term,
        status: this.status,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage
      }
      this.$loading = true
      Promo.get({
          params: params
        })
        .then(result => {
          if (result.data.promos)
            this.promos = result.data.promos
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
