<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3">
            <current-booking></current-booking>
          </div>
          <div class="col-md-3">
            <departed-trips-today></departed-trips-today>
          </div>
          <div class="col-md-3">
            <cancelled-trips-today></cancelled-trips-today>
          </div>

          <div class="col-md-3">
            <online-passengers-booked-today></online-passengers-booked-today>
          </div>
        </div>

      </div>
      <div class="col-md-8">
        <top-routes class="mb-4"></top-routes>

      </div>
      <div class="col-md-4">
        <top-highest-trip-sales-today class="mb-4" v-if="user.can_view_sales"></top-highest-trip-sales-today>
        <total-sales-today v-if="user.can_view_sales"></total-sales-today>
      </div>
    </div>
  </div>
</template>

<script>
import CancelledTripsToday from '../../../System/Analytics/CancelledTripsToday.vue'
import CurrentBooking from '../../../System/Analytics/CurrentBooking.vue'
import DepartedTripsToday from '../../../System/Analytics/DepartedTripsToday.vue'
import OnlinePassengersBookedToday from '../../../System/Analytics/OnlinePassengersBookedToday.vue'
import PassengersBookedToday from '../../../System/Analytics/PassengersBookedToday.vue'
import TopHighestTripSalesToday from '../../../System/Analytics/TopHighestTripSalesToday.vue'
import TopRoutes from '../../../System/Analytics/TopRoutes.vue'
import TotalSalesToday from '../../../System/Analytics/TotalSalesToday.vue'
import StatsCard from "../../../UIComponents/Cards/StatsCard.vue"

export default {
  components: {
    StatsCard,
    TopRoutes,
    CancelledTripsToday,
    DepartedTripsToday,
    PassengersBookedToday,
    TopHighestTripSalesToday,
    TotalSalesToday,
    OnlinePassengersBookedToday,
    CurrentBooking
  },
  data() {
    return {
      user: null
    }
  },
  created: function () {
    this.user = this.$store.state.user

  }
}
</script>

<style></style>
