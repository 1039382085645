<template>
  <div class="row">
    <div class="col-md-4">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">
                Generate Online Sales Report
              </h4>
            </div>
            <div class="card-body">
              <div class="form-group">
                <label>Start Date</label>
                <ValidationProvider name="start_date" rules="required" v-slot="{ passed, failed }">
                  <el-date-picker v-model="form.start_date" type="date" placeholder="Pick a day" class="form-group"
                    :class="[
        { 'has-danger': failed },
        { 'has-success': passed }]">
                  </el-date-picker>
                  <br><span class="error-text" v-if="failed">Start date is required</span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <label>End Date</label>
                <ValidationProvider name="end_date" v-slot="{ passed, failed }">
                  <el-date-picker v-model="form.end_date" type="date" placeholder="Pick a day" class="form-group"
                    :class="[
        { 'has-danger': failed },
        { 'has-success': passed }]">
                  </el-date-picker>
                  <br><span class="error-text" v-if="failed">Start date is required</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="card-footer text-right">
              <el-button type="info" native-type="button" @click="downloadReport()">Download</el-button>
              <el-button type="info" native-type="submit">Search</el-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>

    <div class="col-md-3">
      <stats-card :type="'success'" icon="fa fa-money" :small-title="'Total Sales'" :title="total_sales"></stats-card>
    </div>

    <div class="col-md-3">
      <stats-card :type="'warning'" icon="fa fa-ticket" :small-title="'Total Tickets'"
        :title="total_tickets"></stats-card>
    </div>

    <div class="col-md-12 card">
      <!-- <div v-if="isAllowed('SETTINGS', 'UPDATE') ">
        <el-input v-model="online_booking_code"></el-input>
        <el-button @click="handleGeneratePdf()">GENERATE PDF</el-button>
        <hr>
        <div v-if="online_booking">
          <a :href="online_booking.ticket_pdf_path" target="_blank">DOWNLOAD PDF</a>
          <el-button @click="sendEmail()">SEND EMAIL TO ONLINE CODE {{ online_booking.code }}</el-button>

        </div>
      </div> -->
      <div class="card-header">
        <div class="category"><span>Tickets</span></div>
      </div>
      <div class="card-body row">
        <div class="col-sm-2">
          <el-select class="btn-block" v-model="pagination.perPage" placeholder="Per page">
            <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="col-sm-10">
          <div class="pull-right">
        
            <el-input placeholder="Search" prefix-icon="el-icon-search" v-model="term" class="btn-block">
            </el-input>

          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped" header-row-class-name="text-primary" :data="tickets" style="width: 100%">
            <el-table-column :min-width="50" class-name="td-actions" label="Code">
              <template slot-scope="props">
                {{ props.row.ticket_detail.code }}
              </template>
            </el-table-column>

            <el-table-column width="150" class-name="td-actions" label="Passenger Name">
              <template slot-scope="props">
                {{ props.row.ticket_detail.passenger.full_name }}
              </template>
            </el-table-column>

            <el-table-column :min-width="60" class-name="td-actions" label="Ticket">
              <template slot-scope="props">
                {{ props.row.type == 1 ? 'OW' : 'RT' }} {{
                $store.state.passenger_types[props.row.passenger_type] }}

              </template>
            </el-table-column>

            <el-table-column :min-width="50" class-name="td-actions" label="Route">
              <template slot-scope="props">
                {{ props.row.booking.route.code }} {{ props.row.accommodation.code }}

              </template>
            </el-table-column>

            <el-table-column :min-width="60" class-name="td-actions" label="Date/Time">
              <template slot-scope="props">
                {{ props.row.booking.booking_date }}<br> {{ props.row.booking.formatted_time }}
              </template>
            </el-table-column>
            <el-table-column :min-width="50" class-name="td-actions" label="Total Fare">
              <template slot-scope="props">
                {{ money(props.row.ticket_detail.total_fare) }}
              </template>
            </el-table-column>
            <el-table-column :min-width="80" class-name="td-actions" label="Created On">
              <template slot-scope="props">
                {{ props.row.created_on }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Found {{ pagination.total }} data</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total">
          </p-pagination>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";
import {
  Report
} from '@/resources/report'
import {
  Ticket
} from '@/resources/ticket'
import StatsCard from '../../UIComponents/Cards/StatsCard.vue';
extend("required", required);

export default {
  components: { StatsCard },
  data() {
    return {
      online_booking: null,
      online_booking_code: null,
      form: {
        start_date: new Date(),
        end_date: new Date()
      },
      tickets: [],
      total_sales: 0,
      total_tickets: 0,
      selected: null,
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      term: null,
    };
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getTickets()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getTickets()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getTickets()
    }
  },
  created: function () {
    this.getTickets()
    // if (this.trip_schedule) this.form = Object.assign({}, this.trip_schedule)
  },
  methods: {
    sendEmail() {
      this.$loading = true
      Ticket.sendEmailToOnline({ code: this.online_booking.code })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Email has been generated',
          });
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleGeneratePdf() {
      this.$loading = true
      Ticket.generateOnlineTickets({ code: this.online_booking_code })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'PDF has been generated',
          });
          this.online_booking = result.data.online_booking
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleGeneratePdf() {
      this.$loading = true
      Ticket.generateOnlineTickets({ code: this.online_booking_code })
        .then(result => {
          this.online_booking = result.data.online_booking
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getDateTime(date) {
      let d = this.$moment(date)
      return d.format('YYYY-MM-DD h:mm a')
    },
    downloadReport() {
      this.$loading = true
      this.form.start_date = this.$moment(this.form.start_date).format('YYYY-MM-DD')
      this.form.end_date = this.$moment(this.form.end_date).format('YYYY-MM-DD')
      Report.getOnlineSalesReport(this.form)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Report has been generated',
          });
          var printWindow = window.open(result.data.url, '_blank');
          printWindow.print();
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
    },
    submit() {
      this.getTickets()
    },
    getTickets() {
      let start_date = this.$moment(this.form.start_date).format('YYYY-MM-DD')
      let end_date = this.$moment(this.form.end_date).format('YYYY-MM-DD')

      let params = {
        term: this.term,
        status: this.status,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        start_date: start_date,
        end_date: end_date,
        user_id: this.user_id,
        is_online: true
      }
      this.$loading = true
      Ticket.soldTickets(params)
        .then(result => {
          this.total_sales = result.data.total
          this.total_tickets = result.data.total_tickets
          if (result.data.tickets)
            this.tickets = result.data.tickets
          this.pagination = result.data.meta
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style></style>
