<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            {{ form.id ? 'Update Port' : 'Create New Port' }}
          </h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Name</label>
                <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
                  <fg-input type="text" :error="failed ? 'The name field is required' : null" :hasSuccess="passed"
                    name="name" v-model="form.name">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Code</label>
                <ValidationProvider name="fein" rules="required" v-slot="{ passed, failed }">
                  <fg-input type="text" :error="failed ? 'The code field format is invalid' : null" :hasSuccess="passed"
                    name="code" v-model="form.code">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>FCA</label>
                <el-input v-model="form.fca" type="number"></el-input>
              </div>
            </div>
            <div class="col-md-6">

              <div class="form-group">
                <label>Terminal Fee</label>
                <el-input v-model="form.terminal_fee" type="number"></el-input>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>LIne Address 1</label>
                <ValidationProvider name="address" rules="required" v-slot="{ passed, failed }">
                  <fg-input type="text" :error="failed ? 'The address field is required' : null" :hasSuccess="passed"
                    name="address" v-model="form.line_address_1">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-6">

              <div class="form-group">
                <label>LIne Address 2</label>
                <ValidationProvider name="fein" v-slot="{ passed, failed }">
                  <fg-input type="text" :error="failed ? 'The code field format is invalid' : null" :hasSuccess="passed"
                    name="code" v-model="form.line_address_2">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4">


              <div class="form-group">
                <label>Contact Number</label>
                <ValidationProvider name="contact_number" rules="required" v-slot="{ passed, failed }">
                  <fg-input type="text" :error="failed ? 'The contact number field is required' : null"
                    :hasSuccess="passed" name="contact_number" v-model="form.contact_number">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4">

              <div class="form-group">
                <label>TICKET FORMAT</label>
                <el-select v-model="form.ticket_format_type">
                  <el-option :value="0" label="DEFAULT"></el-option>
                  <el-option :value="1" label="THERMAL"></el-option>
                </el-select>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>TICKET EXPIRATION (IN MONTHS)</label>
                <el-input v-model="form.ticket_expiration_months" type="number"></el-input>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>REBOOKING CHARGE BEFORE DEPARTURE (%)</label>
                <el-input v-model="form.rebooking_charge_rate_before_departure" type="number"></el-input>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>REBOOKING CHARGE AFTER DEPARTURE (%)</label>
                <el-input v-model="form.rebooking_charge_rate_after_departure" type="number"></el-input>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>REFUND CHARGE BEFORE DEPARTURE (%)</label>
                <el-input v-model="form.refund_charge_rate_before_departure" type="number"></el-input>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>REFUND CHARGE AFTER DEPARTURE (%)</label>
                <el-input v-model="form.refund_charge_rate_after_departure" type="number"></el-input>
              </div>
            </div>


          </div>


        </div>
        <div class="card-footer text-right">
          <p-button type="info" native-type="submit">Save</p-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";
import {
  Port
} from '@/resources/port'

extend("required", required);

export default {
  props: ['port'],
  data() {
    return {
      form: {
        code: "",
        name: "",
      }
    };
  },
  watch: {
    branch: function () {
      if (this.port) this.form = Object.assign({}, this.port)
    }
  },
  created: function () {
    if (this.port) this.form = Object.assign({}, this.port)
  },
  beforeDestroy() {
    this.form = {
      code: "",
      name: "",
    }
  },
  methods: {
    submit() {
      this.$loading = true

      let request = this.form.id ? Port.update(this.form) : Port.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Port has been saved',
          });
          this.$emit('save', true)
        })
        .catch(error => {
          let message = error.response.data.message || 'Unable to save this time'
          this.$notify({
            type: 'danger',
            title: "Error",
            text: message,
          });
        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style></style>
