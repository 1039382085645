var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.form.id ? 'Update Promo' : 'Create New Promo')+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Name")]),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The name field is required' : null,"hasSuccess":passed,"name":"name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Start Date")]),_c('ValidationProvider',{attrs:{"name":"start_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('el-date-picker',{staticClass:"form-group",class:[
                { 'has-danger': failed },
                { 'has-success': passed }],attrs:{"type":"date","placeholder":"Pick a day"},model:{value:(_vm.form.start_date),callback:function ($$v) {_vm.$set(_vm.form, "start_date", $$v)},expression:"form.start_date"}}),_c('br'),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Start date is required")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("End Date")]),_c('ValidationProvider',{attrs:{"name":"end_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
                var failed = ref.failed;
return [_c('el-date-picker',{staticClass:"form-group",class:[
                { 'has-danger': failed },
                { 'has-success': passed }],attrs:{"type":"date","placeholder":"Pick a day"},model:{value:(_vm.form.end_date),callback:function ($$v) {_vm.$set(_vm.form, "end_date", $$v)},expression:"form.end_date"}}),_c('br'),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("End date is required")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Days to auto adjust end date")]),_c('ValidationProvider',{attrs:{"name":"days_to_auto_adjust_end_date"}},[_c('el-input-number',{staticClass:"form-group",attrs:{"placeholder":"0"},model:{value:(_vm.form.days_to_auto_adjust_end_date),callback:function ($$v) {_vm.$set(_vm.form, "days_to_auto_adjust_end_date", $$v)},expression:"form.days_to_auto_adjust_end_date"}})],1)],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Advance days")]),_c('ValidationProvider',{attrs:{"name":"advance_days"}},[_c('el-input-number',{staticClass:"form-group",attrs:{"placeholder":"0"},model:{value:(_vm.form.advance_days),callback:function ($$v) {_vm.$set(_vm.form, "advance_days", $$v)},expression:"form.advance_days"}})],1)],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Is Round Trip?")]),_c('br'),_c('el-switch',{attrs:{"active-text":"Yes","inactive-text":"No"},model:{value:(_vm.form.is_round_trip),callback:function ($$v) {_vm.$set(_vm.form, "is_round_trip", $$v)},expression:"form.is_round_trip"}})],1),(_vm.form.is_round_trip)?_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Round trip validity days")]),_c('ValidationProvider',{attrs:{"name":"return_validity_days"}},[_c('el-input-number',{staticClass:"form-group",attrs:{"placeholder":"0"},model:{value:(_vm.form.return_validity_days),callback:function ($$v) {_vm.$set(_vm.form, "return_validity_days", $$v)},expression:"form.return_validity_days"}})],1)],1):_vm._e()]),_c('div',{staticClass:"card-footer text-right"},[_c('p-button',{attrs:{"type":"info","native-type":"submit"}},[_vm._v("Save")])],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }