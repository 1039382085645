<template>
  <div>
    <div class="row">
      <div class="col-md-12" v-if="user && user.unpaid_invoices">
        <el-alert effect="dark" :closable="false" type="warning">
          <div style="font-size: 20px;"> You have an outstanding invoice. <el-link style="font-size: 20px;"
              href="client/invoices"> Click here to
              view</el-link></div>
        </el-alert>
        <br>
      </div>
    </div>
  </div>
</template>

<script>

import StatsCard from "../../../UIComponents/Cards/StatsCard.vue"

export default {
  components: {
    StatsCard,
  },
  data() {
    return {
      user: null
    }
  },
  created: function () {
    this.user = this.$store.state.user

  }
}
</script>

<style></style>
