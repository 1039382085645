<template>
  <div v-if="vessel">
    <el-tabs type="border-card" v-model="active_tab">
      <el-tab-pane :label="accommodation.name" v-for="accommodation in accommodations" :key="accommodation.id" :name="accommodation.code" style="overflow: auto;">        
        <vessel-seat-plan-form :accommodation="accommodation" :vessel_detail="vessel" v-if="active_tab == accommodation.code" :booking="booking"></vessel-seat-plan-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { Vessel } from '@/resources/vessel'
import { Accommodation } from '@/resources/accommodation'
import VesselSeatPlanForm from './VesselSeatPlanForm.vue';

extend("required", required);

export default {
  components: { VesselSeatPlanForm },
  props: ['vessel', 'booking'],
  data() {
    return {
      active_tab: null,
      form: null,
      accommodations: []
    };
  },
  watch: {
    vessel: function () {
      if (this.vessel) this.form = Object.assign({}, this.vessel)
    }
  },
  created: function () {
    if (this.vessel) this.form = Object.assign({}, this.vessel)
    this.getAccommodations()
  },
  beforeDestroy() {
    this.form = {
      code: "",
      name: "",
    }
  },
  methods: {

    getAccommodations() {
      this.$loading = true
      Accommodation.get({ params: {} })
        .then(result => {
          if (result.data.accommodations)
            this.accommodations = result.data.accommodations
            if (this.accommodations.length) this.active_tab = this.accommodations[0].code
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style></style>
