<template>
  <div v-loading="loading">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">
              Update
            </h4>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label>Type</label>
              <el-select v-model="form.type">
                <el-option :value="0" label="Before"></el-option>
                <el-option :value="1" label="After"></el-option>
                <el-option :value="2" label="Full"></el-option>
              </el-select>
            </div>
          </div>
          <div class="card-footer text-right">
            <p-button type="info" native-type="submit">Save</p-button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";

import {
  RefundedTicket
} from '@/resources/refunded_ticket'

extend("required", required);

export default {
  props: ['refunded'],
  data() {
    return {
      form: {
        approved_amount: 0
      },
      loading: false
    };
  },
  watch: {
    refunded: function () {
      if (this.refunded) this.form = Object.assign({}, this.refunded)
    }
  },
  created: function () {
    if (this.refunded) this.form = Object.assign({}, this.refunded)
  },
  methods: {
    async submit() {
      // const { value: res } = await Swal.fire({
      //   title: "Attention!",
      //   text: "Approving this transaction is irreversible. Do you wish to continue?",
      //   icon: "warning",
      //   confirmButtonClass: "btn btn-success",
      //   buttonsStyling: true
      // })

      this.loading = true
      RefundedTicket.update(this.form.id, this.form)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Saved',
          });
          this.$emit('save', true)
        })
        .catch(error => {
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style></style>
