<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            {{ form.id ? 'Update Promo Item' : 'Create New Promo Item' }}
          </h4>
        </div>
        <div class="card-body">
          <div class="form-group">
              <label>Equivalent Price</label>
              <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
          
                <el-select v-model="form.price_id" filterable
      remote
      :remote-method="getPrices"
      :loading="loading"
      class="form-group" :class="[
        { 'has-danger': failed },
        { 'has-success': passed }]"
      >
      <el-option
        v-for="price in prices"
        :key="price.id"
        :label="price.route.code+' '+price.accommodation.code+' '+$store.state.passenger_types[price.passenger_type]"
        :value="price.id">
      </el-option>

                </el-select>
              </ValidationProvider>
            </div>
         
          <div class="form-group">
            <label>Amount</label>
            <ValidationProvider name="amount">
              <el-input-number v-model="form.amount" placeholder="0" class="form-group">
              </el-input-number>
            </ValidationProvider>
          </div>

          <div class="form-group">
              <label>Limit of Passengers per accommodation</label>
              <ValidationProvider name="amount">
                <el-input-number v-model="form.limit" placeholder="0" class="form-group">
                </el-input-number>
              </ValidationProvider>
            </div>

            <div class="form-group">
                <label>Return trip Amount</label>
                <ValidationProvider name="amount">
                  <el-input-number v-model="form.return_trip_amount" placeholder="0" class="form-group">
                  </el-input-number>
                </ValidationProvider>
              </div>

          
        </div>
        <div class="card-footer text-right">
          <p-button type="info" native-type="submit">Save</p-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";
import {
  PromoItem
} from '@/resources/promo_item'

import {
  Price
} from '@/resources/price'

extend("required", required);

export default {
  props: ['promo_item'],
  data() {
    return {
      form: {
        name: null
      },
      loading: false,
      prices: []
    };
  },
  watch: {
    promo: function () {
      if (this.promo_item) this.form = Object.assign({}, this.promo_item)
    }
  },
  created: function () {
    if (this.promo_item) this.form = Object.assign({}, this.promo_item)
    console.log(this.promo_item)
  },
  methods: {
    submit() {
      this.$loading = true

      let request = this.form.id ? PromoItem.update(this.form) : PromoItem.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Promo has been saved',
          });
          this.$emit('save', true)
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getPrices(term) {
      let params = {
        term: term,
        page: 1
      }

      this.$loading = true
      Price.get({
        params: params
      })
        .then(result => {
          if (result.data.prices)
            this.prices = result.data.prices
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style></style>
