<template>
<ValidationObserver v-slot="{ handleSubmit }">
  <form @submit.prevent="handleSubmit(submit)">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          {{ form.id ? 'Update Promo' : 'Create New Promo' }}
        </h4>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label>Name</label>
          <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
            <fg-input type="text" :error="failed ? 'The name field is required' : null" :hasSuccess="passed" name="name" v-model="form.name">
            </fg-input>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <label>Start Date</label>
          <ValidationProvider name="start_date" rules="required" v-slot="{ passed, failed }">
            <el-date-picker v-model="form.start_date" type="date" placeholder="Pick a day" class="form-group" :class="[
                { 'has-danger': failed },
                { 'has-success': passed }]">
            </el-date-picker>
            <br><span class="error-text" v-if="failed">Start date is required</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <label>End Date</label>
          <ValidationProvider name="end_date" v-slot="{ passed, failed }" rules="required">
            <el-date-picker v-model="form.end_date" type="date" placeholder="Pick a day" class="form-group" :class="[
                { 'has-danger': failed },
                { 'has-success': passed }]">
            </el-date-picker>
            <br><span class="error-text" v-if="failed">End date is required</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <label>Days to auto adjust end date</label>
          <ValidationProvider name="days_to_auto_adjust_end_date">
            <el-input-number v-model="form.days_to_auto_adjust_end_date" placeholder="0" class="form-group">
            </el-input-number>
          </ValidationProvider>
        </div>

        <div class="form-group">
          <label>Advance days</label>
          <ValidationProvider name="advance_days">
            <el-input-number v-model="form.advance_days" placeholder="0" class="form-group">
            </el-input-number>
          </ValidationProvider>
        </div>

        <div class="form-group">
          <label>Is Round Trip?</label><br>
          <el-switch v-model="form.is_round_trip" active-text="Yes" inactive-text="No">
          </el-switch>
        </div>

        <div class="form-group" v-if="form.is_round_trip">
          <label>Round trip validity days</label>
          <ValidationProvider name="return_validity_days">
            <el-input-number v-model="form.return_validity_days" placeholder="0" class="form-group">
            </el-input-number>
          </ValidationProvider>
        </div>

      </div>
      <div class="card-footer text-right">
        <p-button type="info" native-type="submit">Save</p-button>
      </div>
    </div>
  </form>
</ValidationObserver>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";
import {
  Promo
} from '@/resources/promo'

extend("required", required);

export default {
  props: ['promo'],
  data() {
    return {
      form: {
        name: null,
        start_date: "",
        end_date: null
      }
    };
  },
  watch: {
    promo: function () {
      if (this.promo) this.form = Object.assign({}, this.promo)
    }
  },
  created: function () {
    if (this.promo) this.form = Object.assign({}, this.promo)
  },
  methods: {
    submit() {
      this.$loading = true
      this.form.start_date = this.$moment(this.form.start_date).format('YYYY-MM-DD')
      this.form.end_date = this.$moment(this.form.end_date).format('YYYY-MM-DD')

      let request = this.form.id ? Promo.update(this.form) : Promo.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Promo has been saved',
          });
          this.$emit('save', true)
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style></style>
